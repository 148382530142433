import "./App.css"

function App() {
    return (
        <div className="App">
            <div className="body-img-container"></div>
            <div className="container">
                <div className="info-container">
                    <img src="/avatar.png" alt="Avatar" className="logo-img"/>
                    <div className="profile-area">
                        <h1 className="title">KRIanSE Team</h1>
                        <p className="description">Студия разработки IT решений</p>
                    </div>
                    <div className="tabs">
                        <div className="tab active">
                            <span className="tab-span">Наши проекты</span>
                        </div>
                    </div>
                    <div className="links">
                        <button onClick={() => window.location.href = "https://t.me/free_vpn_for_all_bot"}
                                className="link">
                            <img src="/bot.png" alt="TGBot" className="icon"/>
                            <div className="texts">
                                <h2 className="title-text">Бесплатный OutLine VPN</h2>
                                <p className="link-text">https://t.me/free_vpn_for_all_bot</p>
                            </div>
                        </button>
                    </div>
                    <div className="tabs">
                        <div className="tab active">
                            <span className="tab-span">Контакты</span>
                        </div>
                    </div>
                    <div className="links">
                        <button onClick={() => window.location.href = "https://t.me/KriAnSe_Team"} className="link">
                            <div className="icon-2">
                                <div className="bg" style={{background: "rgb(31, 101, 222)"}}></div>
                                <svg width="20" height="20" viewBox="0 0 24 24"
                                     xmlns="http://www.w3.org/2000/svg" color="#1f65de" className="svg-icon">
                                    <path
                                        d="m9.417 15.181-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218z"
                                        fill="#039be5"></path>
                                </svg>
                            </div>
                            <div className="texts">
                                <h2 className="title-text">Telegram</h2>
                                <p className="link-text">https://t.me/KriAnSe_Team</p>
                            </div>
                        </button>
                        <button onClick={() => window.location.href = "https://boosty.to/krianse"} className="link">
                            <div className="icon-2">
                                <div className="bg" style={{background: "#ffffff"}}></div>
                                <img src="/boosty.svg" alt="BoostyIcon" style={{width: 25}}/>
                            </div>
                            <div className="texts">
                                <h2 className="title-text">Boosty</h2>
                                <p className="link-text">https://boosty.to/krianse</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
